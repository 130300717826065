import { RouteObject } from 'react-router-dom';
import { accountsRoutes } from './features/accounts';
import { customersRoutes } from './features/customers';
import { geneticCalculatorRoutes } from './features/geneticCalculator';
import { geneticTestsRoutes } from './features/geneticTests';
import { husbandryRoutes } from './features/husbandry';
import { inventoryRoutes } from './features/inventory';
import { offspringRoutes } from './features/offspring';
import { ordersRoutes } from './features/orders';
import { mainRouteComponents } from './utils/routeComponents';
import { breedingRoutes } from 'routes/features/breeding';
import { canonicalRoutes } from 'routes/features/canonical';
import { messagesRoutes } from 'routes/features/messages';
import { projectsRoutes } from 'routes/features/projects';
import { reactComponentsRoutes } from 'routes/reactComponents';

const {
  Analytics,
  HomePage,
  Tags,
  Inventory,
  Index,
  Events,
  Event,
  DashboardPage,
  CollectionNFS,
  LabelSettings,
  Stores,
  SavedSearches,
  PushNotificationsList,
  Waitlist,
  ReactPlayground,
  NotFoundPage,
  HusbandryInventory,
  QRScanner,
} = mainRouteComponents;

export const routes: RouteObject[] = [
  {
    path: '',
    lazy: HomePage,
  },
  {
    path: 'animals',
    children: [
      { path: '', lazy: Inventory },
      { path: 'husbandry', lazy: HusbandryInventory },
      { path: 'tags', lazy: Tags },
      { path: 'searches', lazy: SavedSearches },
      { path: 'search/matches', lazy: PushNotificationsList },
    ],
  },
  {
    path: ':region/c/*',
    children: [
      { path: ':category/:animal?/:animalSubType?/traits', lazy: Index },
      { path: '', children: canonicalRoutes },
    ],
  },
  ...geneticCalculatorRoutes,
  ...inventoryRoutes,
  ...breedingRoutes,
  ...projectsRoutes,
  ...accountsRoutes,
  ...offspringRoutes,
  ...geneticTestsRoutes,
  ...ordersRoutes,
  ...husbandryRoutes,
  ...messagesRoutes,
  ...customersRoutes,
  ...reactComponentsRoutes,
  {
    path: ':region/reptile-expos',
    children: [
      { path: ':eventType?', lazy: Events },
      { path: ':eventType/:slug/:id', lazy: Event },
    ],
  },
  { path: 'dashboard', lazy: DashboardPage },
  { path: 'stores/:storeUsername/collection', lazy: CollectionNFS },
  { path: ':region/stores', lazy: Stores },
  {
    path: 'users/:username',
    children: [
      { path: 'collection', lazy: CollectionNFS },
      { path: 'label_settings', lazy: LabelSettings },
    ],
  },
  { path: 'waitlists', lazy: Waitlist },
  { path: 'analytics', lazy: Analytics },
  { path: 'testing/react-playground', lazy: ReactPlayground },
  { path: 'qr-scanner', lazy: QRScanner },
  { path: '*', element: <NotFoundPage /> },
];
