import {
  AnimalActivity,
  ActivityMetaTypeEnum,
  ActivityFrequencyEnum,
  ActivityTypeEnum,
  WeightActivity,
  LengthActivity,
  FeedingActivity,
  ShedActivity,
  DefecationActivity,
  CleaningActivity,
  WaterChangeActivity,
  BrumationActivity,
  OvulationActivity,
  FollicleActivity,
  PairingActivity,
  LockActivity,
  CopulationActivity,
  GravidActivity,
  LaidActivity,
  HatchedActivity,
  BornActivity,
} from "api/sdk";

// TODO: Get rid of Omit

export const isWeightActivity = (activity: Omit<AnimalActivity, "">): activity is WeightActivity => {
  return activity.activityType === ActivityTypeEnum.Weight;
};
export const isLengthActivity = (activity: Omit<AnimalActivity, "">): activity is LengthActivity => {
  return activity.activityType === ActivityTypeEnum.Length;
};
export const isFeedingActivity = (activity: Omit<AnimalActivity, "">): activity is FeedingActivity => {
  return activity.activityType === ActivityTypeEnum.Feeding;
};
export const isShedActivity = (activity: Omit<AnimalActivity, "">): activity is ShedActivity => {
  return activity.activityType === ActivityTypeEnum.Shed;
};
export const isDefecationActivity = (activity: Omit<AnimalActivity, "">): activity is DefecationActivity => {
  return activity.activityType === ActivityTypeEnum.Defecation;
};
export const isCleaningActivity = (activity: Omit<AnimalActivity, "">): activity is CleaningActivity => {
  return activity.activityType === ActivityTypeEnum.Cleaning;
};
export const isWaterChangeActivity = (activity: Omit<AnimalActivity, "">): activity is WaterChangeActivity => {
  return activity.activityType === ActivityTypeEnum.WaterChange;
};
export const isBrumationActivity = (activity: Omit<AnimalActivity, "">): activity is BrumationActivity => {
  return activity.activityType === ActivityTypeEnum.Brumation;
};
export const isOvulationActivity = (activity: Omit<AnimalActivity, "">): activity is OvulationActivity => {
  return activity.activityType === ActivityTypeEnum.Ovulation;
};
export const isFollicleActivity = (activity: Omit<AnimalActivity, "">): activity is FollicleActivity => {
  return activity.activityType === ActivityTypeEnum.Follicle;
};
export const isPairingActivity = (activity: Omit<AnimalActivity, "">): activity is PairingActivity => {
  return activity.activityType === ActivityTypeEnum.Pairing;
};
export const isLockActivity = (activity: Omit<AnimalActivity, "">): activity is LockActivity => {
  return activity.activityType === ActivityTypeEnum.Lock;
};
export const isCopulationActivity = (activity: Omit<AnimalActivity, "">): activity is CopulationActivity => {
  return activity.activityType === ActivityTypeEnum.Copulation;
};
export const isGravidActivity = (activity: Omit<AnimalActivity, "">): activity is GravidActivity => {
  return activity.activityType === ActivityTypeEnum.Gravid;
};
export const isLaidActivity = (activity: Omit<AnimalActivity, "">): activity is LaidActivity => {
  return activity.activityType === ActivityTypeEnum.Laid;
};
export const isHatchedActivity = (activity: Omit<AnimalActivity, "">): activity is HatchedActivity => {
  return activity.activityType === ActivityTypeEnum.Hatched;
};
export const isBornActivity = (activity: Omit<AnimalActivity, "">): activity is BornActivity => {
  return activity.activityType === ActivityTypeEnum.Born;
};

export type SoloActivity =
  | WeightActivity
  | LengthActivity
  | FeedingActivity
  | ShedActivity
  | DefecationActivity
  | CleaningActivity
  | WaterChangeActivity
  | BrumationActivity
  | OvulationActivity
  | FollicleActivity
  | GravidActivity;
export type DualActivity = PairingActivity | LockActivity | CopulationActivity;
export type OffspringActivity = LaidActivity | HatchedActivity | BornActivity;

export const isSoloActivity = (activity: Omit<AnimalActivity, "">): activity is SoloActivity => {
  return activityTypes[activity.activityType].metaType === ActivityMetaTypeEnum.Solo;
};

export const isDualActivity = (activity: Omit<AnimalActivity, "">): activity is DualActivity => {
  return activityTypes[activity.activityType].metaType === ActivityMetaTypeEnum.Dual;
};

export const isOffspringActivity = (activity: Omit<AnimalActivity, "">): activity is OffspringActivity => {
  return activityTypes[activity.activityType].metaType === ActivityMetaTypeEnum.Offspring;
};

type FieldMeta = { type: string; default?: unknown; label?: string };

export type ActivityStringField = FieldMeta & { type: "string" };
export type ActivityBooleanField = FieldMeta & { type: "boolean" };
export type ActivityDateField = FieldMeta & { type: "date" };

export type ActivityNumberField = FieldMeta & { type: "int" | "float"; minValue?: number; maxValue?: number; default?: number; unit?: string };
export type ActivityMeasurementField = Omit<ActivityNumberField, "type"> & { type: "weight" | "length" };

export type ActivityChoiceField = FieldMeta & { type: "choice"; choices: { value: string; label: string }[] };

export type ActivityListField = FieldMeta & { type: "list"; innerType: Record<string, ActivityDataField> };
export type ActivityDataField = ActivityStringField | ActivityBooleanField | ActivityMeasurementField | ActivityNumberField | ActivityChoiceField | ActivityDateField;
export type ActivityType = {
  metaType: ActivityMetaTypeEnum;
  frequency: ActivityFrequencyEnum;
  breedingRelated: boolean;
  femaleOnly: boolean;
  animalCategories: string[] | null;
  data: Record<string, ActivityDataField>;
};

export const isStringField = (fieldType: ActivityDataField): fieldType is ActivityStringField => {
  return fieldType.type === "string";
};
export const isBooleanField = (fieldType: ActivityDataField): fieldType is ActivityBooleanField => {
  return fieldType.type === "boolean";
};
export const isMeasurementField = (fieldType: ActivityDataField): fieldType is ActivityMeasurementField => {
  return fieldType.type === "weight" || fieldType.type === "length";
};
export const isNumberField = (fieldType: ActivityDataField): fieldType is ActivityNumberField => {
  return fieldType.type === "int" || fieldType.type === "float";
};
export const isChoiceField = (fieldType: ActivityDataField): fieldType is ActivityChoiceField => {
  return fieldType.type === "choice";
};
export const isListField = (fieldType: ActivityDataField): fieldType is ActivityListField => {
  return fieldType.type === "list";
};

export const isDateField = (fieldType: ActivityDataField): fieldType is ActivityDateField => {
  return fieldType.type === "date";
};

export const baseFields = ["user", "activityDate", "activityType", "privateNotes", "imageUrl", "animal", "otherAnimal", "offspringGroup"];
export const readonlyFields = ["id"];

export const activityTypes: Record<ActivityTypeEnum, ActivityType> = {
  weight: {
    metaType: "solo",
    frequency: "recurring",
    breedingRelated: false,
    femaleOnly: false,
    animalCategories: null,
    data: {
      weight: {
        type: "weight",
        minValue: 0,
      },
      weightChange: {
        type: "float",
        default: 0,
        unit: "%",
      },
    },
  },
  length: {
    metaType: "solo",
    frequency: "recurring",
    breedingRelated: false,
    femaleOnly: false,
    animalCategories: null,
    data: {
      length: {
        type: "length",
        minValue: 0,
      },
    },
  },
  feeding: {
    metaType: "solo",
    frequency: "recurring",
    breedingRelated: false,
    femaleOnly: false,
    animalCategories: null,
    data: {
      feederItems: {
        type: "list",
        innerType: {
          preyFood: {
            type: "choice",
            default: "unknown",
            label: "Food",
            choices: [
              {
                value: "unknown",
                label: "N/A",
              },
              {
                value: "rat",
                label: "Rat",
              },
              {
                value: "mouse",
                label: "Mouse",
              },
              {
                value: "ASF-rat",
                label: "ASF Rat",
              },
              {
                value: "mealworm",
                label: "Mealworm",
              },
              {
                value: "cricket",
                label: "Cricket",
              },
              {
                value: "roach",
                label: "Roach",
              },
              {
                value: "meal-replacement",
                label: "Meal Replacement",
              },
              {
                value: "dry-feed",
                label: "Dry Feed",
              },
              {
                value: "vegetables",
                label: "Vegetables",
              },
              {
                value: "quail",
                label: "Quail",
              },
              {
                value: "rabbit",
                label: "Rabbit",
              },
              {
                value: "chicken",
                label: "Chicken",
              },
              {
                value: "superworm",
                label: "Superworm",
              },
              {
                value: "pinhead-cricket",
                label: "Pinhead Cricket",
              },
              {
                value: "hornworm",
                label: "Hornworm",
              },
              {
                value: "silkworm",
                label: "Silkworm",
              },
              {
                value: "black-soldier-fly-larvae",
                label: "Black Soldier Fly Larvae",
              },
              {
                value: "waxworm",
                label: "Waxworm",
              },
              {
                value: "fruit-fly",
                label: "Fruit Fly",
              },
              {
                value: "butterworm",
                label: "Butterworm",
              },
              {
                value: "hamster",
                label: "Hamster",
              },
              {
                value: "gerbil",
                label: "Gerbil",
              },
              {
                value: "lizard",
                label: "Lizard",
              },
              {
                value: "egg",
                label: "Egg",
              },
              {
                value: "other",
                label: "Other",
              },
            ],
          },
          preyState: {
            type: "choice",
            default: "unknown",
            label: "State",
            choices: [
              {
                value: "unknown",
                label: "N/A",
              },
              {
                value: "live",
                label: "Live",
              },
              {
                value: "frozen",
                label: "Frozen/Thawed",
              },
              {
                value: "prekilled",
                label: "Pre-killed",
              },
              {
                value: "other",
                label: "Other",
              },
            ],
          },
          preySize: {
            type: "choice",
            default: "unknown",
            label: "Size",
            choices: [
              {
                value: "unknown",
                label: "N/A",
              },
              {
                value: "extra-small",
                label: "Extra Small",
              },
              {
                value: "small",
                label: "Small",
              },
              {
                value: "medium",
                label: "Medium",
              },
              {
                value: "large",
                label: "Large",
              },
              {
                value: "extra-large",
                label: "Extra Large",
              },
              {
                value: "pinky",
                label: "Pinky",
              },
              {
                value: "fuzzy",
                label: "Fuzzy",
              },
              {
                value: "pup",
                label: "Pup",
              },
              {
                value: "weanling",
                label: "Weanling",
              },
              {
                value: "hopper",
                label: "Hopper",
              },
              {
                value: "jumbo",
                label: "Jumbo",
              },
              {
                value: "other",
                label: "Other",
              },
            ],
          },
          preyCount: {
            type: "int",
            default: 1,
            label: "Quantity",
            minValue: 1,
          },
          preyRefused: {
            type: "boolean",
            label: "Refused",
          },
        },
      },
    },
  },
  shed: {
    metaType: "solo",
    frequency: "once",
    breedingRelated: false,
    femaleOnly: false,
    animalCategories: ["reps"],
    data: {},
  },
  defecation: {
    metaType: "solo",
    frequency: "once",
    breedingRelated: false,
    femaleOnly: false,
    animalCategories: null,
    data: {},
  },
  cleaning: {
    metaType: "solo",
    frequency: "recurring",
    breedingRelated: false,
    femaleOnly: false,
    animalCategories: null,
    data: {},
  },
  water_change: {
    metaType: "solo",
    frequency: "recurring",
    breedingRelated: false,
    femaleOnly: false,
    animalCategories: null,
    data: {},
  },
  brumation: {
    metaType: "solo",
    frequency: "once",
    breedingRelated: true,
    femaleOnly: false,
    animalCategories: ["reps"],
    data: {
      isBrumating: {
        type: "boolean",
      },
    },
  },
  ovulation: {
    metaType: "solo",
    frequency: "once",
    breedingRelated: true,
    femaleOnly: true,
    animalCategories: null,
    data: {},
  },
  follicle: {
    metaType: "solo",
    frequency: "recurring",
    breedingRelated: true,
    femaleOnly: true,
    animalCategories: null,
    data: {
      follicleSize: {
        type: "float",
        minValue: 0,
        unit: "mm",
      },
    },
  },
  pairing: {
    metaType: "dual",
    frequency: "once",
    breedingRelated: true,
    femaleOnly: false,
    animalCategories: null,
    data: {},
  },
  lock: {
    metaType: "dual",
    frequency: "once",
    breedingRelated: true,
    femaleOnly: false,
    animalCategories: null,
    data: {},
  },
  copulation: {
    metaType: "dual",
    frequency: "once",
    breedingRelated: true,
    femaleOnly: false,
    animalCategories: null,
    data: {},
  },
  gravid: {
    metaType: "solo",
    frequency: "once",
    breedingRelated: true,
    femaleOnly: true,
    animalCategories: null,
    data: {},
  },
  laid: {
    metaType: "offspring",
    frequency: "once",
    breedingRelated: true,
    femaleOnly: false,
    animalCategories: [
      "anoles",
      "axolotls",
      "bds",
      "chams",
      "colubrids",
      "crocs",
      "frogs",
      "gs",
      "igs",
      "isopods",
      "mons",
      "newts",
      "oamphs",
      "ols",
      "other-invertebrates",
      "pythons",
      "tarantulas",
      "tegus",
      "toads",
      "torts",
      "true-spiders",
      "turtles",
      "uros",
    ],
    data: {
      goodEggCount: {
        type: "int",
        default: 0,
        label: "Good Eggs",
        minValue: 0,
      },
      badEggCount: {
        type: "int",
        default: 0,
        label: "Bad Eggs",
        minValue: 0,
      },
      estimatedHatchDate: {
        type: "date",
        label: "Estimated No. of Days to Hatch",
      },
    },
  },
  hatched: {
    metaType: "offspring",
    frequency: "once",
    breedingRelated: true,
    femaleOnly: false,
    animalCategories: [
      "anoles",
      "axolotls",
      "bds",
      "chams",
      "colubrids",
      "crocs",
      "frogs",
      "gs",
      "igs",
      "isopods",
      "mons",
      "newts",
      "oamphs",
      "ols",
      "other-invertebrates",
      "pythons",
      "tarantulas",
      "tegus",
      "toads",
      "torts",
      "true-spiders",
      "turtles",
      "uros",
    ],
    data: {
      maleCountHatched: {
        type: "int",
        label: "Male",
        minValue: 0,
      },
      femaleCountHatched: {
        type: "int",
        label: "Female",
        minValue: 0,
      },
      unsexedCountHatched: {
        type: "int",
        label: "Unsexed",
        minValue: 0,
      },
    },
  },
  born: {
    metaType: "offspring",
    frequency: "once",
    breedingRelated: true,
    femaleOnly: false,
    animalCategories: [
      "boas",
      "eastern-blue-tongued-skinks",
      "gars",
      "halmahera-blue-tongued-skinks",
      "indonesian-blue-tongued-skinks",
      "irian-jaya-blue-tongued-skinks",
      "kei-island-blue-tongued-skinks",
      "merauke-blue-tongued-skinks",
      "monkey-tailed-skinks",
      "northern-blue-tongued-skinks",
      "other-blue-tongued-skinks",
      "ribbons",
      "scorpions",
      "tanimbar-blue-tongued-skinks",
      "water-snakes",
      "western-blue-tongued-skinks",
    ],
    data: {
      maleCountBorn: {
        type: "int",
        label: "Male",
        minValue: 0,
      },
      femaleCountBorn: {
        type: "int",
        label: "Female",
        minValue: 0,
      },
      unsexedCountBorn: {
        type: "int",
        label: "Unsexed",
        minValue: 0,
      },
    },
  },
};
