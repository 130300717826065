import { useEffect, useMemo, useState } from 'react';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'pages/Dashboard/components/Tabs/Tabs';
import { MANAGE_TAGS_TAB, SELLER_SETTINGS_TAB } from 'pages/Messenger/utils/constants';
import { useAboutMe } from 'context/UserContext/UserContext';
import { UnseenThreadCounts } from 'api/sdk';
import { OPEN_MOBILE_FILTERS } from 'hooks/messages/messages';
import { setCookie } from 'services/cookie';
import { WEBSOCKETS_MESSAGE_TYPES } from '../../../../../../../../src/js/user-messages-web-sockets';
import style from './styles.module.scss';

type PathValueHandlers = {
  [key: string]: (path: string) => void;
};

const MessengerTabs = ({ unseenCounts }: { unseenCounts?: UnseenThreadCounts }) => {
  const navigate = useNavigate();
  const { about } = useAboutMe();

  const oldMessenger = 'old-messenger';

  const { pathname, search, href } = window.location;
  const tabs = useMemo(() => {
    const baseTabs: Array<Tab> = [
      {
        title: 'Inbox',
        value: `${pathname}?collection=inbox`,
      },
      {
        title: `Offers${unseenCounts?.offers ? ` (${unseenCounts.offers})` : ''}`,
        value: `${pathname}?filter=offers&collection=all`,
      },
      {
        title: 'Archive',
        value: `${pathname}?collection=archive`,
      },
      {
        title: `Needs Reply${unseenCounts?.needsReply ? ` (${unseenCounts.needsReply})` : ''}`,
        value: `${pathname}?filter=needs-reply&collection=all`,
      },
      {
        title: `Unread${unseenCounts?.collection ? ` (${unseenCounts.collection})` : ''}`,
        value: `${pathname}?filter=unseen&collection=all`,
      },
      {
        title: 'To You',
        value: `${pathname}?collection=to-you`,
      },
      {
        title: 'From You',
        value: `${pathname}?collection=from-you`,
      },
      {
        title: 'All',
        value: `${pathname}?collection=all`,
      },
      {
        title: 'Manage Tags',
        value: MANAGE_TAGS_TAB,
      },
    ];

    if (about?.hasStore) {
      baseTabs.push({
        title: 'Seller Settings',
        value: SELLER_SETTINGS_TAB,
        icon: faCog,
      });
    }

    return baseTabs;
  }, [pathname, JSON.stringify(unseenCounts), about]);
  const [selected, setSelected] = useState(search ? pathname + search : tabs[0].value);

  const handlePathValue: PathValueHandlers = {
    [oldMessenger]: (_pathValue: string) => {
      setCookie('mm_messages_version', 'old', 365 * 10);
      window.location.reload();
    },
    [SELLER_SETTINGS_TAB]: (_pathValue: string) => {
      window.open(
        `${window.location.origin}/stores/${about?.username}/edit#inquiry-settings`,
        '_self'
      );
    },
    [MANAGE_TAGS_TAB]: (pathValue: string) => {
      document.dispatchEvent(new CustomEvent(OPEN_MOBILE_FILTERS, { detail: { path: pathValue } }));
    },
    default: (pathValue: string) => {
      document.dispatchEvent(
        new CustomEvent(WEBSOCKETS_MESSAGE_TYPES.MESSENGER_TABS_CLICK, {
          detail: { path: pathValue },
        })
      );
      navigate(`${pathValue}&page=1`);
    },
  };

  const onChangeTab = (pathValue: string) => {
    const handle = handlePathValue[pathValue] || handlePathValue.default;
    handle(pathValue);
  };

  useEffect(
    function setDefaultTab() {
      setSelected(search ? pathname + search : tabs[0].value);
    },
    [href]
  );

  return (
    <div className={style.tabsWrapper}>
      <Tabs onChange={onChangeTab} value={selected} tabs={tabs} />
    </div>
  );
};

export default MessengerTabs;
