/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `already_responded` - Already Responded
 * * `mark_sold` - Mark Sold
 * * `mark_on_hold` - Mark On Hold
 * * `not_available` - Not Available
 * * `decline` - Decline Inquiry
 * * `reopen` - Reopen
 * * `block` - Block User
 * * `unblock` - Unblock User
 * * `archive` - Archive
 * * `unarchive` - Unarchive
 * * `auction_report` - Report Auction
 * * `auction_offer_to_runner_up` - Offer to Runner-Up
 * * `auction_accept_offer` - Accept Offer
 * * `mark_as_shipped` - Mark as Shipped
 * * `mark_as_received` - Mark as Received
 * @export
 */
export const ActionCodeEnum = {
    AlreadyResponded: 'already_responded',
    MarkSold: 'mark_sold',
    MarkOnHold: 'mark_on_hold',
    NotAvailable: 'not_available',
    Decline: 'decline',
    Reopen: 'reopen',
    Block: 'block',
    Unblock: 'unblock',
    Archive: 'archive',
    Unarchive: 'unarchive',
    AuctionReport: 'auction_report',
    AuctionOfferToRunnerUp: 'auction_offer_to_runner_up',
    AuctionAcceptOffer: 'auction_accept_offer',
    MarkAsShipped: 'mark_as_shipped',
    MarkAsReceived: 'mark_as_received'
} as const;
export type ActionCodeEnum = typeof ActionCodeEnum[keyof typeof ActionCodeEnum];


export function ActionCodeEnumFromJSON(json: any): ActionCodeEnum {
    return ActionCodeEnumFromJSONTyped(json, false);
}

export function ActionCodeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionCodeEnum {
    return json as ActionCodeEnum;
}

export function ActionCodeEnumToJSON(value?: ActionCodeEnum | null): any {
    return value as any;
}

