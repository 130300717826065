import { fetchWithAppVersion } from '../../../../src/js/utils';

// This constant controls:
// - how often we hit the server with isTyping events
// - how long a typing dot-dot-dot indicator shows for on the client-side before
//   going away
// - the amount we debounce onIsTyping in order to avoid double typing
//   indicators from showing
export const IS_TYPING_PERIOD_MS = 30 * 1000;

// In general, we use regular JavaScript events to propagate the WebSockets information throughout the JavaScript system.
// This helps us avoid issues around double-loading web-sockets listeners when dependencies are required from other files.
//
// All events should be dispatched (and listened to) on `document`, not on `window`
export const NEW_MESSAGE_RECEIVED_WHILE_IN_MESSENGER_JS_EVENT_NAME =
  'receivedNewMessageWhileInMessenger';
export const MESSAGE_SEEN_COUNTS_CHANGED_JS_EVENT_NAME = 'messageSeenCountsChanged';
export const ON_TYPING_EVENT_NAME = 'onTypingEvent';
export const MESSAGE_MARKED_SEEN = 'messageMarkedSeen';
export const OPEN_MOBILE_FILTERS = 'openMobileFilters';

interface MessageCounters {
  unseen: number;
}

export const updateMessageCounters = ({ unseen }: MessageCounters) => {
  // This part is used to communicate to the React.js event
  if (typeof unseen !== 'number' || unseen < 0) return;

  document.dispatchEvent(
    // `CustomEvent` is used instead of `Event` because we want to add a payload.
    new CustomEvent(MESSAGE_SEEN_COUNTS_CHANGED_JS_EVENT_NAME, {
      detail: { ...(unseen && { unseen }) },
    })
  );
};

export const openMobileFilters = () => document.dispatchEvent(new CustomEvent(OPEN_MOBILE_FILTERS));
export const markedSeenCounter = ({ unseen }: MessageCounters) => {
  if (typeof unseen !== 'number' || unseen < 0) return;
  document.dispatchEvent(
    new CustomEvent(MESSAGE_MARKED_SEEN, {
      detail: { unseen },
    })
  );
};

export const startTypingIndicator = (threadId: number) => {
  fetchWithAppVersion(`/messages/is-typing/${threadId}/`).catch((error) => {
    console.error(error);
  });
};
