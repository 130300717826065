/* tslint:disable */
/* eslint-disable */
/**
 * Morphmarket APIs
 * API documentation generated by DRF spectacular
 *
 * The version of the OpenAPI document: 1.0.0 (v1)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionResponseResultEnum,
    ActionResponseResultEnumFromJSON,
    ActionResponseResultEnumFromJSONTyped,
    ActionResponseResultEnumToJSON,
} from './ActionResponseResultEnum';

/**
 * 
 * @export
 * @interface ActionResponse
 */
export interface ActionResponse {
    /**
     * 
     * @type {ActionResponseResultEnum}
     * @memberof ActionResponse
     */
    result: ActionResponseResultEnum;
    /**
     * 
     * @type {string}
     * @memberof ActionResponse
     */
    messageText: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionResponse
     */
    needsRefresh: boolean;
}

export function ActionResponseFromJSON(json: any): ActionResponse {
    return ActionResponseFromJSONTyped(json, false);
}

export function ActionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ActionResponseResultEnumFromJSON(json['result']),
        'messageText': json['message_text'],
        'needsRefresh': json['needs_refresh'],
    };
}

export function ActionResponseToJSON(value?: ActionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ActionResponseResultEnumToJSON(value.result),
        'message_text': value.messageText,
        'needs_refresh': value.needsRefresh,
    };
}

