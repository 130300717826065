import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NestedMenuDrawer } from 'components/experimental/Drawer/Drawer';
import { useAboutMe } from 'context/UserContext/UserContext';
import { useMobileProfileOptions } from './useMobileProfileOptions';

export const ProfileMenu: React.FC = () => {
  const { about } = useAboutMe();
  const avatar = about?.storeLogoThumbUrl || about?.imageThumbUrl;
  const options = useMobileProfileOptions();

  const icon = !!avatar ? (
    <img src={avatar} style={{ borderRadius: '100%', width: 24, height: 24, objectFit: 'cover' }} />
  ) : (
    <FontAwesomeIcon icon={faCircleUser} />
  );

  return (
    <NestedMenuDrawer options={options} fontAwesomeIcon={icon} ariaLabel="User Profile Menu" />
  );
};
